import { EditOutlined } from '@ant-design/icons';
import { formatHoursDuration } from '@shopopop/backoffice-frontend-utils';
import { Alert, Button, Form, Modal } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeliveryTimeBody from '../DateSelector/DeliveryTimeForm';

interface EditDeliveryTimeSlotModalProps {
  timeValue: {
    startHour: string;
    endHour: string;
  };
  handleUpdateTimeSlot: (values: { startDate: string; endDate: string }) => void;
  timeSlotDelay: number;
}
function EditDeliveryTimeSlotModal({ timeValue, timeSlotDelay, handleUpdateTimeSlot }: EditDeliveryTimeSlotModalProps) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const intitialFormvalues = Form.useWatch([], form);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };
  const handleSubmit = () => {
    form.validateFields().then(({ startDate, endDate }) => {
      handleUpdateTimeSlot({ startDate: dayjs(startDate).toISOString(), endDate: dayjs(endDate).toISOString() });
      closeModal();
    });
  };

  return (
    <>
      <Button type="link" onClick={openModal} icon={<EditOutlined />} />
      <Modal
        title={t('UPDATING_DATE_OR_TIMESLOT')}
        open={isModalOpen}
        onOk={() => form.submit()}
        onCancel={handleCancel}
        okText={t('UPDATE_BUTTON')}
        cancelText={t('CANCEL_UPDATE')}
        okButtonProps={{
          disabled:
            dayjs(intitialFormvalues?.startDate).toISOString() === dayjs(timeValue.startHour).toISOString() &&
            dayjs(intitialFormvalues?.endDate).toISOString() === dayjs(timeValue.endHour).toISOString(),
        }}
        // lageur de la modale choisie arbitrairement avec le design peut être modifié au besoin
        width={720}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            startDate: dayjs(timeValue.startHour),
            endDate: dayjs(timeValue.endHour),
          }}
          onFinish={handleSubmit}
        >
          <DeliveryTimeBody form={form} preventionDelay={timeSlotDelay} />
        </Form>
        <Alert message={t('DELIVERY_TIMESLOT_INSTRUCTION').replace('[CREATION_TIME]', formatHoursDuration(timeSlotDelay))} type="info" showIcon />
      </Modal>
    </>
  );
}

export default EditDeliveryTimeSlotModal;
