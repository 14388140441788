import { DeleteOutlined } from '@ant-design/icons';
import { buildCustomHtml } from '@shopopop/backoffice-frontend-utils';
import { useScreenSize } from '@shopopop/react-hooks';
import { Button, Flex, Form, Modal, Popconfirm, Select, Typography } from 'antd';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArchivedReasons } from '../../interfaces/ArchivedReason';

const { Text } = Typography;

interface ModalArchivedDeliveryProps {
  postArchived: (values: ArchivedReasons) => Promise<void>;
  recipientName: string;
}

function ModalArchivedDelivery({ postArchived, recipientName }: Readonly<ModalArchivedDeliveryProps>): ReactNode {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const { mobileMode } = useScreenSize();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const reasonValue = Form.useWatch('reason', form);

  const archivedReasonsOptions = [
    { value: ArchivedReasons.CANCEL_PICKUP_POINT, label: t('CANCEL_PICKUP_POINT') },
    { value: ArchivedReasons.CANCEL_RECIPIENT, label: t('CANCEL_RECIPIENT') },
    { value: ArchivedReasons.NO_SHOPPER, label: t('NO_SHOPPER') },
    { value: ArchivedReasons.DUPLICATED_DELIVERY, label: t('DUPLICATED_DELIVERY') },
  ];

  const modalTitle = buildCustomHtml(t('ARCHIVING_PROCESS_TITLE'), [{ key: '[RECIPIENT_NAME]', value: recipientName }]);

  const handleSubmit = () => {
    setPopupOpen(true);
  };

  const handlePopupConfirm = async() => {
    await setPopupOpen(false);
    await postArchived(form.getFieldValue('reason'));
    form.resetFields();
    setIsModalOpen(false);
  };

  const handlePopupCancel = () => {
    setPopupOpen(false);
  };

  const handleModalCancel = async() => {
    form.resetFields();
    await setPopupOpen(false);
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        danger
        icon={<DeleteOutlined />}
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        {!mobileMode && t('ARCHIVING_BUTTON')}
      </Button>
      <Modal
        title={modalTitle}
        open={isModalOpen}
        onCancel={handleModalCancel}
        footer={() => (
          <>
            <Button onClick={handleModalCancel}>{t('ARCHIVING_CANCELLATION_BUTTON')}</Button>
            <Popconfirm
              title={t('ARCHIVING_POPCONFIRM_MSG')}
              open={popupOpen}
              onConfirm={handlePopupConfirm}
              onCancel={handlePopupCancel}
              okText={t('ARCHIVING_CONFIRMATION')}
              cancelText={t('ARCHIVING_CANCELLATION')}
            >
              <Button onClick={() => setPopupOpen(true)} type="primary" danger disabled={!reasonValue}>
                {t('ARCHIVING_VALIDATION_BUTTON')}
              </Button>
            </Popconfirm>
          </>
        )}
      >
        <Flex vertical gap="large">
          <Text italic type="secondary">
            {t('ARCHIVING_PROCESS_MSG')}
          </Text>
          <Form layout="vertical" form={form} onFinish={handleSubmit}>
            <Form.Item label={t('ARCHIVING_REASON')} name="reason" rules={[{ required: true, message: t('ARCHIVING_REASON_REQUIRED') }]}>
              <Select placeholder={t('ARCHIVING_REASON_PLACEHOLDER')}>
                {archivedReasonsOptions.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Flex>
      </Modal>
    </>
  );
}

export default ModalArchivedDelivery;
